export default function getVaultTitle(address = '') {
  if (address === '0xf8EcB7D0aC9326A9455C9930aa23EDf63B48F9EA') {
    return 'Masternode Main Vault';
  } else if (address === '0x2171Ae740A1C5A151Bc0B1D0C39Dd25a07996f5b') {
    return 'Masternode Rewards Vault';
  } else if (address === '0xD1995FFe7e8e09c7972B7ae651AB15FdEa6c1154') {
    return 'Staking Main Vault';
  } else if (address === '0x43ACE43473244d666E9B17f6f612A923d6B39BcF') {
    return 'Staking Rewards Vault';
  } else if (address === '0x70adE6843fDBF8bfb2d9Ee31f41489a1Cddd60dE') {
    return 'RWA Pool Main Vault';
  } else if (address === '0x5Da852FB1B194916ad48D32998DFc484470d9781') {
    return 'RWA Pool Rewards Vault';
  } else if (address === '0x135bB0dc45717e39021a5c100beB834562493252') {
    return 'Affiliate - Airdrop Vault';
  } else if (address === '0xFF6b3BAA361E712588257355EeB69a687765472C') {
    return 'Affiliate - Promoter Vault';
  } else if (address === '0xB012F483987214f86291A1B666d8426aEcD9c7A1') {
    return 'Security – Bounty Program Vault';
  } else if (address === '0xe662d7D4197Aa479058EC9B3e5B67beBf905183e') {
    return 'Ecosystem Development & Liquidity Vault';
  } else if (address === '0x5f0E8f3906AAD3eE72e8802D9Ea4Eee27B4d3987') {
    return 'Team Allocation Vault';
  }
  return address;
}
